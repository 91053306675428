<template>
  <div>
    <d-body-top-bar :title="$t('views.events.link-title')">
      <div slot="tree">
        <label class="bg-doinsport-primary ml-1"> {{ $t('general.actions.list') }}</label>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <b-card>
        <b-row
          class="row-style"
          no-gutters
        >
          <b-col class="mt-2 " :cols="getTopNavMenuCol()">
            <d-body-nav-bar
              :tabs="$store.getters['menu/getTabs'].eventsMatchIndex"
            />
          </b-col>
          <b-col class="mt-3 text-right pr-2" :cols="getBtnsCols()">
            <div
              class="has-search"
              @click="displayFromDateCalendar = !displayFromDateCalendar"
            >
              <span class="input-date-picker calendar-size fa fa-calendar form-control-feedback"></span>
              <input
                type="text"
                readonly
                class="background-light-blue-inputs form-control pointer"
                :value="fromDateLabel"
              >
            </div>
          </b-col>
          <b-col class="mt-3 text-right" :cols="getBtnsCols()">
            <div
              class="has-search"
              @click="displayUntilDateCalendar = !displayUntilDateCalendar"
            >
              <span class="input-date-picker calendar-size fa fa-calendar form-control-feedback"></span>
              <input
                type="text"
                readonly
                class="background-light-blue-inputs form-control pointer"
                :value="untilDateLabel"
              >
            </div>
          </b-col>
        </b-row>
        <d-calendar-modal
          identifier="fromDate"
          :show="displayFromDateCalendar"
          @on:calendar-change="onFromDateChange"
        />
        <d-calendar-modal
          identifier="untilDate"
          :show="displayUntilDateCalendar"
          :filterFromDate="filterFromDate"
          @on:calendar-change="onUntilDateChange"
        />
        <div class="d-flex justify-content-center align-items-center spinner-busy" v-if="isBusy">
          <b-spinner
            variant="blue-light-spinner"
            type="grow"></b-spinner>
        </div>
        <template v-else>
          <template
            v-if="events.length > 0"
          >
            <div class="row m-0 p-0">
              <div
                v-for="(event, i) of events.slice()"
                :class="getCardCol()"
                class="card-flex-match"
              >
                <event-card
                  :key="'event-key' + i"
                  :event="event"
                  class="event-card-item "
                  :canceled-event="isCanceled(event)"
                  @on:card-click="goToMatch(event)"
                />
              </div>
            </div>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              align="center"
              pills
              class="mb-4"
            ></b-pagination>
          </template>
          <div v-else class="no-matches"><p class="no-matches-text">{{ $t('views.events.no-match') }}</p></div>
        </template>
      </b-card>
    </div>
  </div>
</template>
<script>
import {getMatches} from "@api/doinsport/services/events/events.api";

export default {
  components: {
    EventCard: () => import('./card/EventCard')
  },
  data: () => ({
    events: [],
    filter: '',
    idClub: null,
    displayFromDateCalendar: false,
    displayUntilDateCalendar: false,
    startDate: null,
    endDate: null,
    isBusy: true,
    perPage: 8,
    rows: 1,
    currentPage: 1,
  }),
  watch: {
    currentPage: function () {
      this.$store.dispatch('events/setCurrentPage', this.currentPage);
      this.loadMatches();
    },
  },
  computed: {
    filterFromDate() {
      return this.$moment(this.startDate).add(1, 'days');
    },
    fromDateLabel() {
      return this.$moment(this.startDate).format('DD-MM-YYYY');
    },
    untilDateLabel() {
      return this.$moment(this.endDate).format('DD-MM-YYYY');
    },

  },
  methods: {
    isCanceled(event) {
      return event.canceled === true;
    },
    onFromDateChange(changedDate) {
      this.startDate = this.$moment(changedDate);
      const clonedStartDate = this.startDate.clone();
      this.endDate = this.$moment(clonedStartDate).add(7, 'days');
      this.dispatchDates();
      this.loadMatches();
    },
    onUntilDateChange(changedDate) {
      this.endDate = this.$moment(changedDate);
      this.dispatchDates();
      this.loadMatches();
    },

    goToMatch(event) {
      this.$router.push({name: 'event', params: {id: event.id}});
    },
    getCardCol() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width > 1332) {
        return 'col-md-3';
      }
      if (width > 991) {
        return 'col-md-4';
      }
      if (width > 1000) {
        return 'col-md-4';
      }
      if (width <= 991) {
        return 'col-md-6';
      }
    },
    getColsClasses() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326 && width >= 924) {
        return '';
      } else {
        return 'mt-2';
      }
    },
    getTopNavMenuCol() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326) {
        return '8';
      } else {
        return '8';
      }
    },
    getBtnsCols() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width > 1332) {
        return '2';
      }
      if (width > 991) {
        return '2';
      }
      if (width > 1000) {
        return '2';
      }
      if (width <= 991) {
        return '6';
      }

    },

    loadMatches() {
      this.isBusy = true;
      this.events = [];

      getMatches(this.perPage, this.$store.getters["events/getCurrentPage"], this.fromDateLabel, this.untilDateLabel)
        .then((response) => {
          this.rows = response.data['hydra:totalItems'];
          this.events = response.data['hydra:member'];
        })

        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
    isValidDate(date) {
      return this.$moment(date, this.$moment.ISO_8601, true).isValid();
    },
    initDates() {
      const storedStartDate = this.$store.getters["events/getStartDate"];
      const storedEndDate = this.$store.getters["events/getEndDate"];

      if (this.isValidDate(storedStartDate) && this.isValidDate(storedEndDate)) {
        this.startDate = this.$moment(storedStartDate);
        this.endDate = this.$moment(storedEndDate);
      } else {
        this.startDate = this.$moment();
        this.endDate = this.$moment().add(7, 'days');
        this.dispatchDates();
      }
    },
    dispatchDates() {
      this.$store.dispatch('events/setStartDate', this.startDate);
      this.$store.dispatch('events/setEndDate', this.endDate);
    },
    initCurrentPage() {
      const storedCurrentPage = this.$store.getters["events/getCurrentPage"];

      if (parseInt(storedCurrentPage) !== 1) {
        this.currentPage = storedCurrentPage;
      }
    }
  },
  created() {
    this.initCurrentPage();
    this.initDates();
    this.loadMatches();
  }
};
</script>

<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
@import "@lazy/events/matches/_event-cards-container.scss";
</style>
